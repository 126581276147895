<template>
  <div>
    <n-upload
      id="pictureUpload"
      action=""
      v-model:file-list="fileListRef"
      @remove="handleRemove"
      @before-upload="upbefore"
      @handleDrop="handleDrop"
      @change="handleDrop"
      :default-upload="false"
      :abstract="false"
      accept=".png,.jpg,.jpeg"
      :headers="Authorization"
      list-type="image-card"
    >
      <p class="title-up-text">{{text}}</p>
    </n-upload>
    <PageLoading :loading="loading" />
  </div>
</template>

<script>
import { defineComponent, ref, watch, nextTick } from "vue";
import { useMessage } from "naive-ui";
import { getToken } from "@/utils/auth.js";
import { ArchiveOutline as ArchiveIcon } from "@vicons/ionicons5";
import axios from "axios";
import { resStatusEnum } from '@/enumerators/http.js';
import env from '@/settings/env.js';
import { uploadIndex } from '@/api/upload.js';
import PageLoading from '@/components/PageLoading/index.vue';
import { toRaw } from '@vue/reactivity'
import { PostBannerTempUpload } from '@/api/banner.js';

import { getFileUniqueStr } from '@/utils/utils.js';
import { correctURL } from '@/utils/url.js';

const { SUCCESS } = resStatusEnum;
const { apiUrlPrefix } = env;

const listData = ref([])
// 数组删除下标
Array.prototype.remove = function (dx, arr) {
  if (isNaN(dx) || dx > arr.length) {
    return false;
  }
  for (var i = 0, n = 0; i < arr.length; i++) {
    if (arr[i] != arr[dx]) {
      arr[n++] = arr[i];
    }
  }
  arr.length -= 1;
};

export default defineComponent({
  components: {
    ArchiveIcon,
    PageLoading
  },
  // 父组件传来的值
  props: {
    text: String,
    removeUrl: String,
    size: String,
    sizenum: String,
    imaData:Array,
    isExerciseBank:Number, // 是否是习题库 习题库时 上传临时文件  1:习题库 2:试卷新增习题
  },
  
  setup(props, context) {
    const { attrs, slots, emit } = context;
    const message = useMessage();
    const OSS = require("ali-oss");
    var imagedata = [];
    var size = Number({ ...props }.size); // 文件大小限制
    var removeUrl = { ...props }.removeUrl; // 删除的接口信息
    const fileListRef = ref({ ...props }.imaData); // 原头像地址
    const isTrue = ref(true)
    const loading = ref(false);
    
    watch(
    () => props.imaData,
    (list) => {
      nextTick(() => {
        fileListRef.value = list
      });
    },
    { immediate: true }
  );
    return {
      token: getToken(),
      // 上传所需Authorization
      Authorization: {
        Authorization: getToken(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      fileListRef:toRaw(fileListRef), // 文件列表
      apiUrlPrefix,
      imagedata:toRaw(imagedata),
      loading,
      // 上传之前
      upbefore(file) {
        // message.info("上传之前");
        var fileData = file.file.file;
        var isJPEG = fileData.name.split(".")[1].toLowerCase() === "jpeg";
        var isJPG = fileData.name.split(".")[1].toLowerCase() === "jpg";
        var isPNG = fileData.name.split(".")[1].toLowerCase() === "png";
        var isPDF = fileData.name.split(".")[1].toLowerCase() === "pdf";
        var format = fileData.name.split(".")[1];
        
        var isLt20MK = fileData.size / 1024 / 1024;
        if (!isJPG && !isJPEG && !isPNG && !isPDF) {
          message.info("上传文件只能是 JPEG/JPG/PNG 格式!");
          isTrue.value = false
        }
        if (isLt20MK > size) {
          message.info("单张文件大小不能超过" + size + "M !");
          isTrue.value = false
        }
        if((isJPG || isJPEG || isPNG || isPDF) && isLt20MK <= size){
          isTrue.value = true
        }
        // console.log("file", file);
        if(isTrue.value==true){
          loading.value = true
          axios
          .get(apiUrlPrefix+"/backend/web/upload/index", {
            id: 1,
            headers: {
              Authorization: getToken(),
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (res) {
            if (res.status == 200) {
              var fileName =
                res.data.data.folder +
                "/" +
                getFileUniqueStr(file.file.file) +
                "." + 
                format; //定义唯一的文件名
              let client = new OSS({
                region: res.data.data.region,
                accessKeyId: res.data.data.accessKeyId,
                accessKeySecret: res.data.data.accessKeySecret,
                bucket: res.data.data.bucket,
                stsToken: res.data.data.securityToken,
              });

              client
                .multipartUpload(fileName, fileData)
                .then(function (result) {
                  let fileUrl = result.res.requestUrls[0];
                  let index= fileUrl.lastIndexOf("?");
                  // if(index){
                  //   if(index>0){
                  //     fileUrl = fileUrl.substring(0,index)
                  //   }
                  // }else{
                  //   fileUrl = result.res.requestUrls[0]
                  // }
                  let srt1 = 'http://'
                  let srt2 = res.data.data.bucket +'.'
                  let srt3 = res.data.data.region+'.aliyuncs.com/'
                  let srt4 = srt1+srt2+srt3+fileName
                  let extension = '.'+fileData.name.split(".")[1]
                  // 上传临时文件

                  const resURL = correctURL('file', result.name);
                  
                  if(props.isExerciseBank==1||props.isExerciseBank==2){
                    let obj = {
                      "Banner[upload][file_url]":resURL,
                      "Banner[upload][file_name]":fileData.name,
                      "Banner[upload][file_size]":fileData.size,
                      "Banner[upload][extension]":extension,
                      "Banner[upload][file_type]":1,
                      "Banner[type]":props.isExerciseBank    // 1:习题 2:试卷新增习题
                    }
                    
                    PostBannerTempUpload(obj).then(res=>{
                    }).catch(error=>{})
                  }
                  imagedata = [{
                    // 前端所需参数
                    name: fileName,
                    url: resURL,
                    reallyname: fileData.name,
                    status: 'finished',
                    is:true,
                    // 后端所需参数
                    file_url:resURL,
                    file_name:fileName,
                    file_size:fileData.size,
                    file_type:'1',
                    extension:extension
                  }];
                  loading.value = false
                  listData.value = toRaw(fileListRef.value)
                  if(listData.value.length>0){
                    let leg = listData.value.length
                    listData.value.forEach((item,index)=>{
                      // console.log("文件变1",listData.value)
                      if(listData.value[index].url==null){
                        listData.value.splice(index,1,imagedata[0])
                        context.emit("imagedata", listData.value);
                      }
                    })
                  } 

                })
                .catch(function (err) {
                  message.info(err);
                });
            }
          })
          .catch(function (err) {
            message.info(err);
          });
        }else{
          // imagedata = []
          // context.emit("imagedata",imagedata);
          // message.info("上传的文件不符合要求");
          return false
        }
      },

      // 文件上传
      handleDrop(file) {
      },
      
      // 文件删除
      handleRemove(e) {
        console.log('文件删除1',e.file.url);
        // 习题是不直接删除 只改本地数据
        if(props.isExerciseBank==1||props.isExerciseBank==2){
          let original = e.fileList  // 原总数据
          let url = e.file.url
          original.some((item, index) => {
            if(url==item.url){
              original.remove(index,original);
              return original
            }
          });
          context.emit("imagedata", original);
          console.log("删除后的数据:",original)
          // context.emit("imagedata", listData.value);
        }else{
          let data = new FormData();
          data.append('image',e.file.url);  
          // 旧数据删除
          axios
            .post(apiUrlPrefix+removeUrl,
              data,{headers: {
                Authorization: getToken(),
                "Content-Type": "application/x-www-form-urlencoded",
              }})
            .then(function (res) {
              // if (res.data.code == 1) {
                message.info("文件删除成功!");

                let original = e.fileList  // 原总数据
                let url = e.file.url
                original.some((item, index) => {
                  if(url==item.url){
                    original.remove(index,original);
                    return original
                  }
                });
                imagedata = original    // 删除后总数据
                context.emit("imagedata", imagedata);
              // }
            })
            .catch(function (err) {
              console.log(err);
            });
        }
      },

    };
  },
});
</script>
<style lang="less">
#UploadMap{
    max-width: 360px;
}

.alone-possess .n-progress-graph-line-rail{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.alone-possess .n-progress-graph-line-fill{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.alone-possess .n-upload-trigger{
    width: 100%;
}

.alone-possess #UploadMap .n-icon.n-icon--depth.n-icon--color-transition{
    background: url(../../../public/img/upload/9789225d.png);
}

.c999{
    color: #999;
    font-size: 14px;
}

.alone-possess #pictureUpload .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
    width: 100px;
    height:100px;
    position: relative;
    background: url(../../../public/img/upload/C6173F69.png) no-repeat;
    background-size: 100%;
}

.alone-possess #pictureUpload .n-upload-trigger.n-upload-trigger--image-card:first-child{
    display: block;
}
.alone-possess #pictureUpload .n-upload-trigger.n-upload-trigger--image-card{
    display: none;
}
.alone-possess .title-up-text{
    font-size: 14px;
    color: #999;
    width: 200px;
    position: absolute;
    left:120px;
}
</style>